<template>
    <v-card elevation="12" shaped class="ma-2">
        <v-card-title>
            <v-row justify="center">
                {{ title }}
            </v-row>
        </v-card-title>
        <v-card-subtitle>
            <div class="ma-4 text-center" ref="box">
                <v-progress-circular :rotate="90" :size="progressBarSize" :indeterminate="indeterminate" :width="progressBarWidth" :value="progress" :color="colorProgress">
                    <div>
                        <h1 class="black--text">{{ Number.parseFloat(progress).toFixed(1) }} %</h1>
                    </div>
                </v-progress-circular>
            </div>
        </v-card-subtitle>
    </v-card>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "-",
        },
        item: {
            type: Object,
        },
    },

    data() {
        return {
            show: false,
            progressBarSize: 100,
            progressBarWidth: 10,
            progress: 0,
            colorProgress: "grey",
            indeterminate: true,
        };
    },

    watch: {
        item: {
            immediate: true,
            handler(newVal) {
                this.calculate(newVal);
            },
        },
    },

    computed: {
        extraStores() {
            return this.item && this.item.stores ? this.item.stores.filter((i) => i.status < 2) : [];
        },
        storesInError() {
            return this.item && this.item.stores ? this.item.stores.filter((i) => i.status >= 2) : [];
        },
    },

    methods: {
        calculate(item) {
            if (item >= 0) {
                this.indeterminate = false;
                this.progress = item;

                if (this.progress == 100) {
                    this.colorProgress = "success";
                } else if (this.progress > 0) {
                    this.colorProgress = "primary";
                } else {
                    this.colorProgress = "grey";
                }
            } else {
                this.indeterminate = true;
            }
        },
        calculateSize() {
            let wi = this.$refs && this.$refs.box && this.$refs.box.clientWidth ? this.$refs.box.clientWidth : 500;

            this.progressBarSize = wi * 0.9;
            this.progressBarWidth = this.progressBarSize * 0.12;
        },
    },

    mounted() {
        this.calculateSize();
        this.calculate(this.item);
    },

    created() {
        window.addEventListener("resize", this.calculateSize);
    },

    destroyed() {
        window.removeEventListener("resize", this.calculateSize);
    },
};
</script>
